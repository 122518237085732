<template>
  <div class="ArticleapplisDetail">
    <div class="zone">
      <div class="indent">
        <div class="left">物品申请档案</div>
      </div>
      <div class="conronone">
        <div class="record">
          <div class="recordleft">
            <img src="@/assets/img/shipment.png" alt="" />
            申请单号:{{ detailData.apply_id }}
          </div>
          <p>{{ detailData.apply_status }}</p>
        </div>
        <div class="particulars">
          <div class="particularsleft">
            <div class="particularsleftone">申请人:</div>
            <div class="particularslefttwo">{{ detailData.apply_user }}</div>
          </div>
          <div class="particularscentre">
            <div class="particularscentreone">申请医院:</div>
            <div class="particularscentretwo">
              {{ detailData.organization_name }}
            </div>
          </div>
          <div class="particularscentre">
            <div class="particularscentreone">创建时间：</div>
            <div class="particularscentretwo">{{ detailData.time_stamp }}</div>
          </div>
        </div>
        <div class="particulars">
          <div class="particularsleft">
            <div class="particularsleftone">备注:</div>
            <div class="particularslefttwo">{{ detailData.memo }}</div>
          </div>
        </div>
      </div>
      <div class="commodity">
        <div class="commodityLeft">物品信息</div>
      </div>
      <div class="conron">
        <div class="sheet" id="printMe">
          <el-table
            :data="tableDataDity"
            stripe
            style="width: 100% height:100%"
            show-summary
            :summary-method="getSummaries"
          >
            <el-table-column label="序号" align="center" width="100">
              <template slot-scope="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column
              prop="article_type"
              label="物品类型"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="batch"
              label="批号"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="platform_unique"
              label="平台"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="note"
              label="物品别名"
              height="30"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="article_name"
              label="物品名称"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="standard"
              label="规格"
              align="center"
            ></el-table-column>
           
            <el-table-column
              prop="reagent_name"
              label="试剂名称"
              align="center"
            ></el-table-column>
            <el-table-column prop="reserve" label="库存量" align="center">
              <template slot-scope="scope">
                <span v-if="!scope.row.edit">
                  {{ scope.row.reserve }}
                </span>
                <el-input
                  v-if="scope.row.edit"
                  v-model="scope.row.reserve"
                  type="Number"
                  min="0"
                  size="small"
                  placeholder="请输入内容"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              prop="apply_num"
              label="申请量"
              align="center"
            ></el-table-column>
            <el-table-column prop="cz" label="操作 " width="60">
              <template slot-scope="scope">
                <div class="caozuo">
                  <el-tooltip
                  popper-class="custom-tooltip-border"
                    effect="light"
                    placement="left"
                    width="15"
                    trigger="hover"
                    content="修改库存"
                    v-if="scope.row.edit == false"
                  >
                    <div
                      class="box"
                      @click="scope.row.edit = true"
                    >
                      <img
                        src="@/assets/img/marshalone.png"
                        alt=""
                        @mouseenter="hoverC('marshal', $event)"
                        @mouseleave="hoverC('marshalone', $event)"
                      />
                    </div>
                  </el-tooltip>
                  <div
                    class="box"
                    @click="scope.row.edit = false"
                    v-if="scope.row.edit == true"
                  >
                    <img
                      src="@/assets/img/wancheng.png"
                      alt=""
                      @click="saveEditBtn(scope.row)"
                    />
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <div class="logistics">物流信息</div>
      <div class="trackingmax">
        <div class="tracking">
          <div class="trackingNumber">
            <div class="trackingNumberleft">
              物流单号:{{ detailData.shipping_code }}
            </div>
          </div>
          <div class="companylogistics">
            <div class="companylogisticsone">
              <div>物流公司:</div>
            </div>
            <div class="companylogisticstwo">
              <div>{{ detailData.shipping_type }}</div>
            </div>
          </div>
          <div class="companylogistics">
            <div class="companylogisticsone">
              <div>物流类型:</div>
            </div>
            <div class="companylogisticstwo">
              <div>{{ detailData.shipping_method }}</div>
            </div>
          </div>
          <div class="companylogistics">
            <div class="companylogisticsone">
              <div>收货人:</div>
            </div>
            <div class="companylogisticstwo">
              <div>{{ detailData.receive_man }}</div>
            </div>
          </div>
          <div class="companylogistics">
            <div class="companylogisticsone">
              <div>电话:</div>
            </div>
            <div class="companylogisticstwo">
              <div>{{ detailData.receive_mobile }}</div>
            </div>
          </div>
          <div class="companylogistics">
            <div class="companylogisticsone">
              <div>收货地址:</div>
            </div>
            <div class="companylogisticstwo">
              <div>{{ detailData.receive_address }}</div>
            </div>
          </div>
        </div>
        <div class="trackingmiss"></div>
        <div class="materials">
          <ul class="layui-timeline">
            <li
              class="layui-timeline-item"
              v-for="(item, index) in information"
              :key="index"
            >
              <div class="time-first">{{ item.ftime }}</div>
              <i class="layui-timeline-axis circle">
                <img src="@/assets/img/transit.png" alt="" v-if="index == 0" />
              </i>
              <div class="layui-timeline-content layui-text">
                <h3 :class="index == 0 ? 'layui-timeline-title' : ''">
                  {{ item.status }}
                </h3>
                <p>{{ item.context }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>


    <!-- 操作按钮 -->
    <div class="buttonBox" v-if="isShowFun">
      <p class="btn0" v-print="'#printMe'" @click="doSomeThing(0)">
        <img src="~@/assets/img/dayin.png" alt="" />
        打印
      </p>
      <p class="btn1" @click="doSomeThing(1)">
        <img src="~@/assets/img/baohui.png" alt="" />
        驳回
      </p>
      <p class="btn2" @click="doSomeThing(2)">
        <img src="~@/assets/img/tongyi.png" alt="" />
        同意
      </p>
      <p class="btn3" @click="dialogVisible = true">
        <img src="~@/assets/img/fahuo.png" alt="" />
        发货
      </p>
    </div>

    <!-- 发货弹框 -->
    <el-dialog
      title="发货"
      center
      :visible.sync="dialogVisible"
      width="30%"
      custom-class="ArticleapplisDetail_fhDialog"
    >
      <p class="inputBox">
        快递公司：
        <el-select
          v-model="shipping_type"
          placeholder="请输入快递公司"
          size="small"
          filterable
          clearable
          remote
        >
          <el-option
            v-for="item in shippingOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </p>
      <p class="inputBox">
        快递单号：
        <el-input
          v-model="shipping_code"
          placeholder="请输入快递单号"
        ></el-input>
      </p>
      <span slot="footer">
        <el-button type="primary" size="small" @click="doSomeThing(3)">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getshippingtype } from '@/api/search.js'
import apiDetail from '@/api/searchDetail.js'
export default {
  name: 'ArticleapplisDetail',
  data() {
    return {
      detailId: '',
      value: '',
      reverse: false,
      tableDataDity: [],
      detailData: {},
      information: [],
      isShowFun: false,
      arrData : ['备件', '耗材', '电子标签'],
      dialogVisible: false, //发货弹框
      shipping_code: '', //快递单号
      shipping_type: 1, //快递公司
      shippingOptions: [
        {
          value: 1,
          label: '顺丰速运',
        },
      ], //快递公司下拉列表
    }
  },
  watch: {
    dialogVisible(value) {
      if (value == false) {
        this.shipping_type = 1
        this.shipping_code = ''
      }
    },
  },
  mounted() {
    this.detailId = this.$route.query.id
    this.isShowFun = this.$route.query.isShowFun
    this.gitarticleapplyDetail()
  },
  methods: {
    // 汇总
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '汇总'
          return
        }
        if (index === 2) {
          return
        }
        if (index === 7) {
          return
        }
        const values = data.map((item) => Number(item[column.property]))
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          sums[index] += ' '
        } else {
          sums[index] = ''
        }
      })

      return sums
    },
    // 详情信息
    gitarticleapplyDetail() {
      apiDetail.articleapplyDetail({ apply_id: this.detailId }).then((res) => {
        this.detailData = res.data

        const data = res.data.articleapply_data
        // const data = res.data.articleapplys_list
        this.tableDataDity = data.map((element,index) => {
          return {
            ...element,
            edit: false,
            article_type: this.arrData[Number(element.article_type)-1],
          }
        })
        this.courier_company = res.data.courier_company
        this.shipping_method = res.data.shipping_method
        this.shipping_code = res.data.shipping_code
        let obj = {}
        if (this.shipping_method === '快递') {
          this.shipping_method = '0'
          obj = {
            shipping_type: '0',
            shipping_code:this.shipping_code,
            com: this.courier_company,
          }
        } else if (this.shipping_method === '冷链') {
          this.shipping_method = '1'
          obj = {
            shipping_type: '1',
            shipping_code: this.shipping_code,
          }
        }
        this.getshippingData(obj)
      })
    },
    // 完成物品信息库存量修改
    saveEditBtn(value) {
      apiDetail
        .add_article_reserve({
          article_id: value.article_id, //物品存货编码标识
          reserve: value.reserve, //库存数量
        })
        .then(
          (res) => {
            if (res.code == 200) {
              this.$message.success('操作成功！')
            }
          },
          (err) => {
            this.$message.success('操作失败！')
          },
        )
        .catch((err) => {
          this.$message.success('操作失败！')
        })
    },
    // 物流运输信息
    getshippingData(obj) {
      getshippingtype(obj).then((res) => {
        this.information = res.data.express_information
      })
    },

    // 操作
    doSomeThing(value) {
      if (value == 0) {
      } else if (value == 1) {
        this.article_apply_statusFun(0)
      } else if (value == 2) {
        this.article_apply_statusFun(2)
      } else if (value == 3) {
        this.add_article_reserveFun()
      }
    },

    // 驳回（状态传0），同意（状态传2）操作
    article_apply_statusFun(choice_apply_status) {
      apiDetail
        .article_apply_status({
          apply_id: this.detailId,
          choice_apply_status, // 申请状态:0,已驳回,1, 待审批;2:待发货;3, 已发货;4,已完成
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message.success('操作成功！')
          }
        })
    },

    // 发货(状态传3)
    add_article_reserveFun() {
      apiDetail
        .article_apply_status({
          apply_id: this.detailId,
          choice_apply_status: 3, // 申请状态:0,已驳回,1, 待审批;2:待发货;3, 已发货;4,已完成
          shipping_code: this.shipping_code, //快递单号
          shipping_type: this.shipping_type, //快递公司
        })
        .then((res) => {
          if (res.code == 200) {
            this.dialogVisible = false
            this.shipping_code = ''
            this.shipping_type = ''
            this.$message.success('操作成功！')
            this.gitarticleapplyDetail()
            this.getshippingData()
          }
        })
    },

    // 鼠标放在操作图标时
    hoverC(a, e) {
      e.target.src = require('@/assets/img/' + a + '.png')
    },
  },
}
</script>

<style lang="scss" scoped>
.ArticleapplisDetail {
  padding-bottom: px(90);

  .caozuo {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 40px;
    color: #d0d7e0;
    .box {
      width: 36px;
      height: 36px;
    }
    img {
      width: 36px;
      height: 36px;
    }
  }
}
.indent {
  height: px(46);
  background-color: transparent;
  display: flex;
  margin-bottom: px(5);
}
.indent2 {
  background-color: transparent;
  display: flex;
  justify-content: flex-end;
}
.indent2 img {
  width: px(15);
  height: px(14);
}
.left {
  justify-content: flex-start;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #111111;
}

.commodity {
  height: px(46);
  background-color: transparent;
  display: flex;
}
.commodityLeft {
  font-weight: bold;
  color: #2d354c;
  line-height: px(56);
}
.logistics {
  height: px(46);
  background-color: transparent;
  display: flex;
  margin-bottom: px(5);
  font-weight: bold;
  color: #2d354c;
  line-height: px(56);
}

.Calendar {
  margin: 0 px(4);
  width: px(300);
}
.conron {
  overflow: auto;
}

.conronone {
  background-color: #fff;
  border-radius: 5px;
  padding: 0 px(8);
}
.record {
  height: px(58);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ebf0f6;
  padding: 0 15px 0 10px;
  .recordleft {
    display: flex;
    color: #111111;
    img {
      width: px(22);
      height: px(28);
      margin-right: 8px;
    }
  }
}
.yuan {
  margin-right: px(6);
  width: px(5);
  height: px(5);
  border-radius: 50%;
  background: #000;
}
.sheet {
  border-radius: px(5);
  padding: px(5);
}
.particulars {
  display: flex;
  border-bottom: 1px solid #ebf0f6;
}
.particularscentre {
  flex: 1;
  padding: px(10) 0;
  margin-left: px(15);
}
.particularsleft {
  flex: 1;
  padding: px(10) 0;
}
.particularsleftone {
  color: #999999;
}
.particularslefttwo {
  font-weight: 500;
  color: #333333;
}
.particularsright {
  flex: 1;
  padding: px(10) 0;
  margin-left: px(15);
}
.particularscentreone {
  color: #999999;
}
.particularscentretwo {
  color: #333333;
}
.particularsrightone {
  color: #999999;
}
.trackingmax {
  display: flex;
  background: #fff;
}
.tracking {
  width: px(560);
  padding: 0 14px 10px 14px;
}
.trackingmiss {
  width: px(7);
  background: #d8d8d8;
  opacity: 0.52;
  filter: blur(4px);
}
.materials {
  flex: 1;
  height: px(307);
}
.trackingNumber {
  height: px(58);
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ebf0f6;
}
.trackingNumberleft {
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #111111;
}
.companylogistics {
  display: flex;
  height: px(50);
  border-bottom: 1px dashed #ebf0f6;
  justify-content: space-between;
}
.companylogistics:nth-last-child(1) {
  border-bottom: none;
}
.companylogisticsone {
  display: flex;
  align-items: center;
  color: #666666;
}
.companylogisticstwo {
  display: flex;
  align-items: center;
  color: #333333;
}
.el-step__iconis-text {
  width: 8px;
  height: 8px;
  background: #e5e5e5;
}
.el-timeline-item {
  padding-bottom: 0;
}
.el-timeline {
  margin-top: px(13);
  margin-left: px(82);
}
.layui-timeline {
  padding-left: px(230);
  padding-top: px(20);
  height: px(287);
  overflow-y: auto;
}

.layui-timeline-item {
  position: relative;
  padding-bottom: px(20);
  margin-top: px(-12);
  color: #999999;
  font-size: px(20);
  .time-first {
    position: absolute;
    left: px(-210);
    top: px(5);
  }
  p {
    color: #999999;
  }
}
.layui-timeline-item:first-child::before {
  display: block;
}
.layui-timeline-item:nth-child(1) {
  .time-first {
    font-size: px(20);
    font-weight: 500;
    color: #00afe9;
    display: inline-block;
  }
}
.layui-timeline-item:last-child::before {
  content: '';
  position: absolute;
  left: 5px;
  top: 0;
  z-index: 0;
  width: 0;
}

.layui-timeline-item::before {
  content: '';
  position: absolute;
  left: -1px;
  top: 0;
  z-index: 0;
  width: 1px;
  height: 100%;
}

.layui-timeline-item::before,
hr {
  background-color: #e6e6e6;
}

.layui-timeline-axis {
  position: absolute;
  left: -5px;
  top: 0;
  z-index: 10;
  width: 20px;
  height: 20px;
  line-height: 20px;
  background-color: #fff;
  color: #5fb878;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
}

.layui-timeline-content {
  padding-left: 25px;
  h3 {
    margin: px(15) 0 px(10) 0;
    color: #999999;
    font-weight: normal;
  }

  .layui-timeline-title {
    position: relative;
    margin-bottom: px(10);
    font-weight: bold;
    color: #c1cdd1;
  }
  .layui-timeline-title:first-child {
    position: relative;
    margin-top: px(20);
    font-weight: bold;
    color: #00afe9;
  }
}

.layui-text {
  line-height: 22px;
  color: #666;
  top: -10px;
}
.circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #e5e5e5;
  font-style: normal;
  img {
    margin-left: -6px;
  }
}
::v-deep .el-table {
  .el-input {
    width: px(90) !important;
    .el-input__inner {
      width: px(130) !important;
      padding: px(5) 0 0 px(5);
    }
  }
}
.buttonBox {
  background: #fff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: px(90);
  padding-right: px(20);
  position: absolute;
  bottom: 0;
  width: px(1680);
  z-index: 1;
  p {
    width: px(83);
    height: px(45);
    border-radius: px(5);
    font-size: px(18);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: px(20);
    box-sizing: border-box;
    img {
      margin-right: px(5);
    }
  }
  .btn0 {
    background: #8591a0;
    color: #fff;
  }
  .btn1 {
    border: 1px solid #ff3370;
    color: #ff3370;
  }
  .btn2 {
    border: 1px solid #00afe9;
    color: #00afe9;
  }
  .btn3 {
    background: #00afe9;
    color: #fff;
  }
}
</style>
<style lang="scss">
.ArticleapplisDetail_fhDialog {
  .el-dialog__body {
    padding: px(10) 0 0 px(20);
  }
  .inputBox {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: px(20);
    .el-input {
      width: px(300);
    }
  }
}
.custom-tooltip-border{
    border: 1px solid #8f9297!important;
}
</style>
